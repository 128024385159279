/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import styles from "./slider.module.scss";
import useIsMobile from "hooks/isMobile";
import ImgixImage from "components/ImgixImage";
import Link from "next/link";
import { useCraftUri } from "lib/craftUri";

export default function ThirdSlider({ slideData = {} }: any): JSX.Element {
  const prepCraftUri = useCraftUri();
  const isMobile = useIsMobile();

  const data = slideData;
  const image =
    (isMobile && data?.mobileImage?.[0]) || data?.backgroundImage?.[0];

  return (
    <div className={styles["third-slider"]}>
      {image && (
        <ImgixImage
          className={styles["third-slider__img"]}
          src={image.url}
          alt={image.title}
          width={image.width}
          height={image.height}
          priority
        />
      )}

      <div className={styles["third-slider__wrap"] + " container"}>
        <div
          className={styles["third-slider__info"]}
          dangerouslySetInnerHTML={{ __html: slideData?.text }}
        ></div>
        {data?.buttonText &&
        (data?.buttonLink?.[0]?.uri || data?.buttonExternalLink) ? (
          <Link
            href={
              data?.buttonLink?.[0]?.uri
                ? prepCraftUri(data?.buttonLink?.[0]?.uri)
                : data?.buttonExternalLink
            }
          >
            <a className="black-button">
              <span>{data?.buttonText}</span>
            </a>
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
