import { useStore } from "hooks/locationContext";
import { SITE_REGION_MAP } from "./config";
import getUri from "./getUri";

export const useCraftUri = (): ((uri: string | null | undefined) => string) => {
  const { initialLocation } = useStore()[0];
  const locationUriStart = getUri(initialLocation);

  return (uri) => {
    if (!uri) return locationUriStart;

    const locationUriNoSlash = locationUriStart.replace(/^\//, "");
    const locationStartRegex = new RegExp(`^/?${locationUriNoSlash}/?`);
    const uriWithoutStateLoc = uri.replace(locationStartRegex, "/");

    const statesInRegex = Object.values(SITE_REGION_MAP).join("|");
    const stateStartRegex = new RegExp(`^/?(${statesInRegex})/?`);
    const uriWithoutState = uriWithoutStateLoc.replace(stateStartRegex, "/");

    const uriSingleSlashes = uriWithoutState
      .split("/")
      .filter(Boolean)
      .join("/");

    const resultUri = `${locationUriStart}/${uriSingleSlashes}`;
    return resultUri;
  };
};
