/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import styles from "./slider.module.scss";
import Link from "next/link";
import useIsMobile from "hooks/isMobile";
import { ImgixImage } from "components/ImgixImage";
import Sofa from "images/svg/Sofa_ic.svg";
import Bed from "images/svg/Bed_ic.svg";
import House from "images/svg/House_ic.svg";
import { useCraftUri } from "lib/craftUri";
import { warnNull } from "utils/monitoring";

export default function FirstSlider(props: any): JSX.Element {
  const prepCraftUri = useCraftUri();
  const data = props.slideData;
  const isMobile = useIsMobile();

  if (!data) {
    return warnNull("no data");
  }

  const image =
    (isMobile && data?.mobileImage?.[0]) || data?.backgroundImage?.[0];

  return (
    <div className={styles["first-slider"]}>
      {image && (
        <ImgixImage
          className={styles["first-slider__img"]}
          src={image.url}
          alt={image.title}
          width={image.width}
          height={image.height}
          priority
        />
      )}
      <div className={styles["first-slider__wrap"] + " container"}>
        <div className={styles["first-slider__info"]}>
          <span className={styles["first-slider__info-title"]}>
            {data.header}
          </span>
          <span className={styles["first-slider__info-price"]}>
            {data.price}
          </span>
          <span className={styles["first-slider__info-text"]}>{data.text}</span>
          <div className={styles["first-slider__info-icons-wrap"]}>
            <ul>
              {data?.homeDesign?.[0]?.bedroom && (
                <li>
                  <Sofa />
                  {data?.homeDesign?.[0]?.bedroom}
                </li>
              )}
              {data?.homeDesign?.[0]?.bathroom && (
                <li>
                  <Sofa />
                  {data?.homeDesign?.[0]?.bathroom}
                </li>
              )}
              {data?.homeDesign?.[0]?.garage && (
                <li>
                  <Bed />
                  {data?.homeDesign?.[0]?.garage}
                </li>
              )}
              {data?.homeDesign?.[0]?.area && (
                <li>
                  <House />
                  {data?.homeDesign?.[0]?.area}
                </li>
              )}
            </ul>
            <div className={styles["first-slider__info-text-wrap"]}>
              <span className={styles["first-slider__info-icons-title"]}>
                {data.locations[0]?.title}
              </span>
              <span className={styles["first-slider__info-icons-text"]}>
                {data?.homeDesign?.[0]?.parent?.title}{" "}
                {data?.homeDesign?.[0]?.title}
              </span>
            </div>
          </div>
          {data?.buttonText &&
          (data?.buttonExternalLink || data?.buttonLink?.[0]?.uri) ? (
            <Link
              href={
                data?.buttonLink?.[0]?.uri
                  ? prepCraftUri(data?.buttonLink?.[0]?.uri)
                  : data?.buttonExternalLink
              }
            >
              <a className="black-button">
                <span>{data?.buttonText}</span>
              </a>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
