import { useEffect, useState } from "react";

export default function Component(): boolean {
  function handleWindowSizeChange() {
    if (typeof window !== "undefined") {
      setWidth(window && window.innerWidth);
    }
  }

  const [width, setWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 768
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleWindowSizeChange);

      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }
  }, []);

  return width <= 768;
}
