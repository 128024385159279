import React from 'react-dom'
import styles from './build-your-home-img.module.scss'
import { ImgixImage } from 'components/ImgixImage'
import { TextAndBackgroundImage_MatrixField } from 'lib/generated/graphql-types'

export type BuildYourHomeImgProps = {
  textAndBackgroundImage: TextAndBackgroundImage_MatrixField[]
}

export default function BuildYourHomeImg({ textAndBackgroundImage }: BuildYourHomeImgProps): JSX.Element {
  const backgroundImage = textAndBackgroundImage?.[0]?.backgroundImage?.[0]
  const text = textAndBackgroundImage?.[0]?.text

  return (
    <div className={styles['build-your-home']}>
      <ImgixImage
        className={styles['build-your-home__img']}
        src={backgroundImage?.url}
        alt={backgroundImage?.title}
        width={backgroundImage?.width}
        height={backgroundImage?.height}
      />
      <div className="container">
        {text && (
          <div
            className={styles['build-your-home__title']}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}
      </div>
    </div>
  )
}
