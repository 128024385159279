import React from 'react-dom'
import styles from './melbourne-displays.module.scss'
import Link from 'next/link'
import { useStore } from 'hooks/locationContext'
import getUri from 'lib/getUri'
import { HeaderTextAndButton_MatrixField } from 'lib/generated/graphql-types'

export type MelbourneDisplaysProps = {
  headerTextAndButton: HeaderTextAndButton_MatrixField[]
}

export default function MelbourneDisplays({ headerTextAndButton }: MelbourneDisplaysProps): JSX.Element {
  const store = useStore()[0]
  const buttonLink = headerTextAndButton?.[0]?.buttonLink?.[0]?.uri

  return (
    <div className={styles['melbourne-displays']}>
      <div className="container">
        <div className={styles['melbourne-displays__wrap']}>
          <div className={styles['melbourne-displays__title'] + ' yellow-span-top'}>{headerTextAndButton?.[0]?.header}</div>
          <div className={styles['melbourne-displays__text-wrap']}>
            <div className={styles['melbourne-displays__text']}>
              <p>{headerTextAndButton?.[0].text}</p>
            </div>
            {buttonLink && headerTextAndButton?.[0].buttonText && (
              <Link href={`${getUri(store.initialLocation)}/${buttonLink}`}>
                <a className="black-button">
                  <span>{headerTextAndButton?.[0].buttonText}</span>
                </a>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
