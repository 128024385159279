/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import styles from "./slider.module.scss";
import Sofa from "images/svg/Sofa-white_ic.svg";
import Bed from "images/svg/Bed-white_ic.svg";
import House from "images/svg/House-white_ic.svg";
import useIsMobile from "hooks/isMobile";
import ImgixImage from "components/ImgixImage";

import Link from "next/link";
import { useCraftUri } from "lib/craftUri";

export default function SecondSlider({ slideData = {} }: any): JSX.Element {
  const prepCraftUri = useCraftUri();
  const isMobile = useIsMobile();

  const image =
    (isMobile && slideData?.mobileImage?.[0]) || slideData?.backgroundImage?.[0];

  return (
    <Link href={
      slideData?.buttonLink?.[0]?.uri
        ? prepCraftUri(slideData?.buttonLink?.[0]?.uri)
        : slideData?.buttonExternalLink
        ? slideData?.buttonExternalLink
        : 'offers'
    }>
      <a>
        <div className={styles["second-slider"]}>
          {image && (
            <ImgixImage
              className={styles["second-slider__img"]}
              src={image.url}
              alt={image.title}
              width={image.width}
              height={image.height}
              priority
            />
          )}

          <div className={styles["second-slider__wrap"] + " " + "container"}>
            <div className={styles["second-slider__info"]}>
              <span className={styles["second-slider__info-title"]}>
                {slideData.header}
              </span>
              <span className={styles["second-slider__info-price"]}>
                {slideData.price}
              </span>
              <span className={styles["second-slider__info-text"]}>
                {slideData.text}
              </span>
              <div className={styles["second-slider__info-icons-wrap"]}>
                <ul>
                  {slideData.homeDesign?.[0]?.bedroom && (
                    <li>
                      <Sofa />
                      {slideData.homeDesign?.[0]?.bedroom}
                    </li>
                  )}
                  {slideData.homeDesign?.[0]?.bathroom && (
                    <li>
                      <Sofa />
                      {slideData.homeDesign?.[0]?.bathroom}
                    </li>
                  )}
                  {slideData.homeDesign?.[0]?.garage && (
                    <li>
                      <Bed />
                      {slideData.homeDesign?.[0]?.garage}
                    </li>
                  )}
                  {slideData.homeDesign?.[0]?.areaSq && (
                    <li>
                      <House />
                      {slideData.homeDesign?.[0]?.areaSq}
                    </li>
                  )}
                </ul>
                <div className={styles["second-slider__info-icons-text-wrap"]}>
                  <span className={styles["second-slider__info-icons-title"]}>
                    {(slideData.locations || [])[0]?.title}
                  </span>
                  <span className={styles["second-slider__info-icons-text"]}>
                    {(slideData.homeDesign?.[0]?.facade || [])[0]?.title
                      ? (slideData.homeDesign?.[0]?.facade || [])[0].title +
                        " Facades"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}
